<template>
  <div class="webhook-options-modal" :style="style" @click.stop.prevent>
    <div class="border-b border-gray-100 p-4">
      <search-input v-model="search" no-margin no-padding :disabled="loading" />
    </div>
    <div>
      <loader :is-loading="loading" />
      <ul class="p-2 overflow-auto max-h-40" @scroll="handleScroll">
        <li
          v-for="webhook in filteredWebhooks"
          :key="webhook.id"
          v-tooltip="{
            content: getTooltipContent(webhook.name),
            placement: 'bottom',
            followCursor: 'vertical'
          }"
          class="p-2 whitespace-nowrap hover:bg-gray-100 cursor-pointer text-gray-700 truncate"
          @mouseenter="checkContentWidth"
        >
          <div class="flex justify-between space-x-2">
            <p
              class="text-ellipsis overflow-hidden whitespace-nowrap"
              data-cy="do-templates-dropdown-option-text"
            >
              {{ webhook.name }}
            </p>
            <button
              class="px-2 py-1 rounded shadow-primary-button bg-gradient-to-bl from-primary-button-1 to-primary-button-2 hover:to-primary-button-hover-2 focus:to-primary-button-active active:from-primary-button-active active:to-primary-button-active text-white font-medium text-xs"
              @click="triggerCustomWebhook(webhook.id)"
            >
              {{ $t("COMMON.RUN") }}
            </button>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import { usePromiseWrapper } from "@/hooks/common";
import applicationsApiService from "@/services/modules/applications";
import Loader from "@/components/ui/Loader.vue";
import SearchInput from "@/components/ui/inputs/SearchInput.vue";

const { items, appId, style } = defineProps<{
  items: Record<string, string>[];
  appId: string;
  style: Record<string, string | undefined>;
}>();

const isTextTruncated = ref(false);

const search = ref("");
const showTooltip = ref(true);
const MAX_NAME_LENGTH = 30;

const filteredWebhooks = computed(() => {
  if (!search.value) {
    return items;
  }
  return items.filter((item) => item.name.includes(search.value));
});

const getTooltipContent = (name: string) =>
  name.length > MAX_NAME_LENGTH && showTooltip ? name : "";
const { fetchWrapper: triggerCustomWebhook, loading } = usePromiseWrapper(
  async (whId: string) => {
    if (!appId || !whId) {
      return;
    }
    await applicationsApiService.triggerCustomWebhook(appId, {
      custom_webhook_id: whId
    });
  }
);

const handleScroll = () => {
  // Hide tooltip when scrolling
  showTooltip.value = false;
  showTooltip.value = true;
};

const checkContentWidth = (event: MouseEvent) => {
  const { offsetWidth, scrollWidth } = event.target as HTMLElement;
  isTextTruncated.value = offsetWidth < scrollWidth;
};
</script>

<style scoped>
.webhook-options-modal {
  @apply h-60 transition-all absolute bg-white rounded shadow-lg w-80 z-100;
}
</style>
